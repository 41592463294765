import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { register as registerSwiper } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://277e571fe8564c7cab038b42d428ed0b@o4504871427637248.ingest.sentry.io/4504909687095296',
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  environment: environment.sentry.env_name,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  enabled: environment.sentry.enabled
});

if (environment.production) {
  enableProdMode();
}

registerSwiper();

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
