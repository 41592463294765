import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country } from '../models/country.model';
import { CountryStore } from '../state/country.store';
@Injectable(
  {providedIn: 'root'}
)
export class CountryService {
    private http = inject(HttpClient);
    private store = inject(CountryStore);


  getAllCountries(): Observable<Country[]> {
    this.store.setLoading(true);
    return this.http.get<Country[]>(`${environment.apiUrl}/countries`).pipe(
      tap(countries => {
        this.store.set(countries);
      }),
      finalize(() => this.store.setLoading(false))
    );
  }

}
