import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '../services/translate.service';

export const httpErrorInterceptor: HttpInterceptorFn = (request, next) => {
    const router = inject(Router);
    const ts = inject(TranslateService);
    const platformId = inject(PLATFORM_ID);

    if (isPlatformServer(platformId)) {
        return next(request);
    }

    return next(request).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse) {
                console.error('error with request. message: ', error.message);

                if (request.url === `${environment.apiUrl}/authenticate` &&
                    (error.status === HttpStatusCode.Unauthorized)) {
                    // 401 when authenticating user will not be intercepted --> incorrect login and/or passw
                    return throwError(() => error);
                }
                if (request.url === `${environment.apiUrl}/register/user` &&
                    (error.status === HttpStatusCode.Conflict)) {
                    // 409 when registering user will not be intercepted --> emailadress already used
                    return throwError(() => error);
                }
                if (request.url === `${environment.apiUrl}/user/changepassword`) {
                    // errors when changing password are not caught
                    return throwError(() => error);
                }
                if (request.url === `${environment.apiUrl}/orders/basket` &&
                    (error.status === HttpStatusCode.NotAcceptable)) {
                    // errors when posting basket are not caught
                    return throwError(() => error);
                }
                if (error.headers.has('www-authenticate')) {
                    // JWT token expitred, passing on the error
                    return throwError(() => error);
                }

                if (request.headers.has('skip-error-interceptor')) {
                    return throwError(() => error);
                }

                if (error.headers.has('error-message') && error.headers.get('error-message')?.includes('discount code not found')) {
                    // 400 when entering wrong discount code will not be intercepted
                    return throwError(() => error);
                }

                let errorMsg = '';
                errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                console.error(errorMsg);

                router.navigate([ts.addLang('/error')], {
                    state: {
                        errorCode: error.status,
                        errorMsg,
                        returnUrl: router.routerState.snapshot.url
                    }
                });

                return throwError(() => errorMsg);
            }

            return throwError(() => error);
        })
    );
};
