import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { filter, map, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryService } from './shared/services/country.service';
import { UrlService } from './shared/services/url.service';
import { UserService } from './shared/services/user.service';

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        RouterOutlet
    ]
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly countryService = inject(CountryService);
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly urlService = inject(UrlService);
  private readonly translateService = inject(TranslocoService);
  private readonly renderer = inject(Renderer2);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly ccService = inject(NgcCookieConsentService);
  private readonly translocoService = inject(TranslocoService);

  title = 'client-website';
  previousUrl = '';
  currentUrl = '';

  private readonly _destroyed: Subject<void>;

  constructor() {
    this._destroyed = new Subject<void>();
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.addPreconnectLink();

    this.translateService.langChanges$.pipe(takeUntil(this._destroyed)).subscribe(() => {
      this.updateLanguage();
    });

    this.translocoService.selectTranslateObject('cookieConsent').subscribe(result => {
      const config= this.ccService.getConfig();
      if (!config.content) {
        config.content = { };
      }

      config.content.message = result.message;
      config.content.dismiss = result.dismiss;
      config.content.link = result.link;
      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    }).unsubscribe();

    this.countryService.getAllCountries().subscribe();
    this.userService.fillUserStore().subscribe();
    this.userService.fillAdressessStore().subscribe();

    this.router.events.pipe(
      takeUntil(this._destroyed),
      filter((event) => event instanceof NavigationStart),
      map(event => {
        if (event instanceof NavigationStart) {
          if (event.url !== '/en/error' &&
            event.url !== '/fr/erreur' &&
            event.url !== '/nl/fout' &&
            event.url !== '/en/account/signup' &&
            event.url !== '/fr/compte/inscription' &&
            event.url !== '/nl/gebruikersaccount/registreren' &&
            event.url !== '/nl/aanmelden' &&
            event.url !== '/fr/connexion' &&
            event.url !== '/en/login') {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.urlService.setPreviousUrl(this.previousUrl);
          }
        }
      })).subscribe();

    this.router.events.pipe(
      takeUntil(this._destroyed),
      filter((event) => event instanceof NavigationEnd),
      map(event => {
        if (event instanceof NavigationEnd) {
          gtag('event', 'page_view', {
            page_title: event.urlAfterRedirects,
            page_path: event.urlAfterRedirects
          });
        }
      })).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
  }

  updateLanguage(): void {
    this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translateService.getActiveLang());
  }

  private addPreconnectLink(): void {
    const link = this.renderer.createElement('link');
    link.setAttribute('rel', 'preconnect');
    link.setAttribute('href', environment.apiUrl);
    this.renderer.appendChild(document.querySelector('head'), link);
  }
}
