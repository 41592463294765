import { isPlatformServer } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { StorageMap } from "@ngx-pwa/local-storage";
import { filter, Observable, of, tap } from "rxjs";
import { LANGUAGE_COOKIE_KEY } from "./shared/constants/constants";
import { TranslateService } from "./shared/services/translate.service";

export function initLanguage(storageMap: StorageMap, translocoService: TranslocoService, platformId: object, router: Router, ts: TranslateService): () => Observable<unknown> {

    if (isPlatformServer(platformId)) {
        return () => of(true);
    }

    return () => storageMap.get(LANGUAGE_COOKIE_KEY)
        .pipe(
            tap(lang => {
                if (typeof lang === 'string' && translocoService.isLang(lang)) {
                    translocoService.setActiveLang(lang);
                    translocoService.setDefaultLang(lang);

                    router.events.pipe(
                        filter(e => e instanceof NavigationEnd),
                        tap(() => {
                            const urlLang = router.routerState.snapshot.url.split('/')[1];
                            if ((urlLang === 'nl' || urlLang === 'en' || urlLang === 'fr')
                                && urlLang !== lang) {
                                const newUrl = '/' + lang + ts.translate(router.routerState.snapshot.url.slice(3), lang);
                                location.href = `${window.origin}${newUrl}`;
                            }
                        })
                    ).subscribe();
                }
            })
        );
}
