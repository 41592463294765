import {
    EnvironmentProviders,
    ErrorHandler,
    inject,
    makeEnvironmentProviders,
    provideAppInitializer,
} from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

export function provideSentryConfig(): EnvironmentProviders[] {
    return [
        makeEnvironmentProviders([
            {
                provide: ErrorHandler,
                useValue: Sentry.createErrorHandler({
                    showDialog: false,
                }),
            },
            {
                provide: Sentry.TraceService,
                deps: [Router],
            },
        ]),
        provideAppInitializer(() => {
            inject(Sentry.TraceService);
        }),
    ];
}
