import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, isDevMode, makeEnvironmentProviders, PLATFORM_ID, provideAppInitializer, provideZoneChangeDetection } from '@angular/core';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentConfig, provideNgcCookieConsent } from 'ngx-cookieconsent';
import { tokenInterceptor } from './shared/interceptors/token.interceptor';
import { BreakpointService } from './shared/services/breakpoint.service';
import { TranslocoHttpLoader } from './transloco-http-loader';

//@ts-expect-error ssr-window geeft problemen
import { getWindow } from 'ssr-window';

import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { provideRouter, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { routes } from './app.routes';
import { initLanguage } from './language.initializer';
import { provideSentryConfig } from './sentry.config';
import { httpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { localeInterceptor } from './shared/interceptors/locale-interceptor';
import { TranslateService } from './shared/services/translate.service';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      domain: getWindow().location.host,
    },
    palette: {
      popup: {
        background: '#000',
      },
      button: {
        background: '#f5dd56',
        border: 'transparent'
      },
    },
    theme: 'edgeless',
    type: 'info',
    content: {
      message:
        'Deze website gebruikt cookies om je de best mogelijke ervaring te garanderen.',
      dismiss: 'Begrepen!',
      link: 'Meer weten',
      href: `${getWindow().location.origin}/en/legal/privacy-en`,
      target: '_self'
    },
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        // werkt niet goed door breakpoint observer
        // provideClientHydration(withEventReplay()),
        provideSentryConfig(),
        CookieService,
        BreakpointService,
        provideHttpClient(
            withFetch(),
            withInterceptorsFromDi(),
            withInterceptors([
                tokenInterceptor,
                localeInterceptor,
                httpErrorInterceptor
            ])
        ),
        provideAppInitializer(() => {
            const initializerFn = (initLanguage)(inject(StorageMap), inject(TranslocoService), inject(PLATFORM_ID), inject(Router), inject(TranslateService));
            return initializerFn();
        }),
        // provideDateFnsAdapter(),
        provideNativeDateAdapter(),
        makeEnvironmentProviders([
            { provide: MAT_DATE_LOCALE, useValue: 'nl-BE' },
            { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Lcm_gocAAAAAP8QgltKVMtDaOBYwUHUMRtXmw5B" }
        ]),
        provideNgcCookieConsent(cookieConfig),
        provideTransloco({
            config: {
                availableLangs: ['en', 'fr', 'nl'],
                defaultLang: 'nl',
                reRenderOnLangChange: true,
                prodMode: !isDevMode()
            },
            loader: TranslocoHttpLoader
        }),
        importProvidersFrom(RecaptchaV3Module)
    ]
};
