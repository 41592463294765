import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { CleanStringService } from '../services/clean-string.service';
import { ProductsService } from '../services/products.service';

export const oldUrlGuard: CanActivateFn = (route) => {
    const ps = inject(ProductsService);
    const router = inject(Router);
    const cs = inject(CleanStringService);

    const id = route.params['id'] as string;
    if (!id) {
        return router.createUrlTree(['/']);
    }

    return ps.getOneById(id).pipe(
        take(1),
        map(art => {
            if (art) {
                return router.createUrlTree([`/en/productdetails/${cs.clean(art.onix.imprintName)}/${cs.clean(art.description)}/${art.barcodeRef}`]);
            }

            return router.createUrlTree(['/']);
        })
    );
};
