import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";

export const localeInterceptor: HttpInterceptorFn = (request, next) => {
    const transloco = inject(TranslocoService);

    const requestHeaderLang = request.headers.get('accept-language') ?? '';
    const requestHeaderLang2 = request.headers.get('content-language') ?? '';
    request = request.clone({
      setHeaders: {
        'accept-language': `${transloco.getActiveLang()},${requestHeaderLang}`,
        'content-language': `${transloco.getActiveLang()},${requestHeaderLang2}`
      }
    });

    return next(request);
};
