import { Routes } from '@angular/router';
import { adminGuard } from './shared/guards/admin.guard';
import { languageGard } from './shared/guards/language.guard';
import { oldUrlGuard } from './shared/guards/old-url.guard';
import { RedirectComponent } from './webshop/redirect/redirect.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then(m => m.routes),
    canActivate: [ adminGuard ]
  },
  {
    path: 'guides/productDetails/:id/:imprint/:title',
    canActivate: [oldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'maps/productDetails/:id/:imprint/:title',
    canActivate: [oldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'globes/productDetails/:id/:imprint/:title',
    canActivate: [oldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'wallmaps/productDetails/:id/:imprint/:title',
    canActivate: [oldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'gifts/productDetails/:id/:imprint/:title',
    canActivate: [oldUrlGuard],
    component: RedirectComponent
  },
  {
    path: 'search/productDetails/:id/:imprint/:title',
    canActivate: [oldUrlGuard],
    component: RedirectComponent
  },
  {
    path: '',
    redirectTo: 'nl',
    pathMatch: 'full'
  },
  // {
  //   path: '**',
  //   redirectTo: 'nl/welkom',
  //   pathMatch: 'full'
  // },
  {
    path: 'nl',
    loadChildren: () => import('./webshop/home/home.routes').then(m => m.routes),
    canActivateChild: [languageGard],
    data: { lang: 'nl' }
  },
  {
    path: 'fr',
    loadChildren: () => import('./webshop/home/home.routes').then(m => m.routes),
    canActivateChild: [languageGard],
    data: { lang: 'fr' }
  },
  {
    path: 'en',
    loadChildren: () => import('./webshop/home/home.routes').then(m => m.routes),
    canActivateChild: [languageGard],
    data: { lang: 'en' }
  },

  {
    path: '**',
    redirectTo: 'nl/welkom',
    pathMatch: 'full'
  }
];
