import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);

    const handleUnauthorized = (request: HttpRequest<unknown>, next: HttpHandlerFn, error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        if (!error.headers.has('www-authenticate')) {
            // no token!
            return throwError(() => error);
        }
        if (!error.headers.get('www-authenticate')!.includes('Jwt expired')) { // TODO: test in combo with http error interceptor
            // token too old, can not be refreshed
            return authService.logout().pipe(concatMap(() => throwError(() => error)));
        }
        // retrieve old token and refresh
        return authService.token$.pipe(
            take(1),
            switchMap(token => {
                return authService.refreshToken(token!).pipe(concatMap(newAccessToken => {
                    request = request.clone({
                        headers: request.headers.set('Authorization', `Bearer ${newAccessToken.token}`)
                    });
                    return next(request);
                }));
            })
        );
    };

    return authService.token$.pipe(
        take(1),
        switchMap(token => {
            if (token &&
                req.url.startsWith(environment.apiUrl)) {
                req = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`)
                });
            }
            return next(req).pipe(
                catchError(res => {
                    if (res instanceof HttpErrorResponse && res.status === 401) { // http-error-interceptor??
                        return handleUnauthorized(req, next, res);
                    }
                        return throwError(() => res);

                })
            );
        })
    );
};
