import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const adminGuard: CanActivateFn = () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    return authService.token$.pipe(
        take(1),
        map(token => {
            if (!token) {
                return router.createUrlTree(['/', 'login']);
            }

            const parsed: { isAdmin: boolean } = jwt_decode(token);
            if (!parsed.isAdmin) {
                return router.createUrlTree(['/', 'login']);
            }

            return parsed.isAdmin;
        })
    );
};
